.page {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 7px;
}

.page-title {
    font-size: 16px;
}

.header {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 16px 16px 0 16px;
}

.tabs {
    width: 100%;
    position: relative;
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    background-color: white;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-radius: 7px;
}

.header-btns {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
}

.btn {
    margin-left: 16px;
}

.ant-tabs-nav-list{
    padding: 0 16px;
}
