.row {
    position: relative;
    display: flex;
    flex-direction: row;
}

.header-btns {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
}

.dialog {
    overflow: auto;
    height: 80vh;
}

.level-sel {
    width: 160px;
}

