.upload-ul li {
  list-style-type: none;
  position: relative;
  text-indent: 8px;
}

.upload-ul li::before {
  content: url("/public/icon/dot.svg");
  position: absolute;
  left: 0;
  top: 3px;
  text-indent: -3px;
}
