*{
    box-sizing: border-box;
}

.logo-content {
    width: 100%;
    height: 30px;
    margin: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-content img {
    height: 100%;
}

.sider-trigger {
    position: absolute;
    left: 18px;
    top: 15px;
    font-size: 18px;
}

.no-space {
    margin: 0;
    padding: 0;
}
