.list-box {
    width: 100%;
    padding: 0 16px;
    background-color: white;
}

.disable {
    pointer-events: none;
}

.expand-on {
    font-size: 12px;
    padding: 16px 26px 16px 16px;
    pointer-events: all;
}

.expand-off {
    font-size: 12px;
    padding: 16px 26px 16px 16px;
    color: #00000000;
    pointer-events: none;
}
