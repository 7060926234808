.form {
    width: 100%;
}

.form-item {
    width: 200px;
}

.h-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.v-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.x-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

