.loading-page {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background: url(./logo/home_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-page-space {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
