.login-page {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: #fff;
}

.login-sider {
  padding: 2rem;
  background: #04050d;
  overflow: auto;
}

.login-sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sider-logo {
  width: 148px;
  margin-bottom: 5rem;
}

.login-sider .ant-divider-inner-text {
  color: #9a9a9a;
}

.login-sider .ant-divider-horizontal.ant-divider-with-text::before {
  background: #9a9a9a;
}

.login-sider .ant-divider-horizontal.ant-divider-with-text::after {
  background: #9a9a9a;
}

.sider-copyright {
  flex: 1;
  color: #a0a7b4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

input:focus {
  outline: 1px solid #1677ff;
}

.tx-login-container {
  display: flex;
  flex: 1;
  min-height: 272px;
  max-height: 272px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  margin-top: 72px;
}

.dingtalk-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 288px;
  min-height: 288px;
  margin-top: 60px;
}

.input-container {
  position: relative;
  height: 3rem;
}

.input-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  background: #0a0e16;
  border-radius: 8px 0px 0px 8px;
}

.input-container .input {
  width: 100%;
  height: 3rem;
  border: none;
  padding: 0 1rem 0 4rem;
  background: #17191f;
  color: #ccc;
  border-radius: 8px;
  font-size: 18;
}

.input-icon-container-span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  outline: none;
  border: none;
  background-color: transparent;
}

.right-content {
  height: auto;
  width: calc(100vw - 30rem);
  min-width: 500px;
  background-image: url(assets/login/background.png);
  background-size: cover;
  position: relative;
}

.right-content-text1 {
  position: absolute;
  bottom: 212px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
}

.right-content-text2 {
  position: absolute;
  bottom: 188px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 32px;
  font-size: 23px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
}

.login-switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
